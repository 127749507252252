
import './MinProfil.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs, doc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import { app } from './firebase-config';
import { recalculateUserPoints } from './recalculatePoints'; // Import the recalculation script
import Regler from './Regler'; // Import the Regler component

const auth = getAuth(app);

const MinProfil = () => {
  const [events, setEvents] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [userPoints, setUserPoints] = useState(0);
  const [hostPoints, setHostPoints] = useState(0);
  const [userName, setUserName] = useState('');
  const [hostedEventsCount, setHostedEventsCount] = useState(0);
  const [participatedEventsCount, setParticipatedEventsCount] = useState(0);
  const [totalEventsCount, setTotalEventsCount] = useState(0);
  const [showHostedEvents, setShowHostedEvents] = useState(false); // State for showing hosted events
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showRules, setShowRules] = useState(false); // State for showing rules

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        setUser(currentUser);
        const db = getFirestore(app);
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(userData.name);
          setUserPoints(userData.points || 0);
          setHostPoints(userData.host_points || 0);
        } else {
          console.error('No such user document!');
        }
      } else {
        navigate('/login');
      }
    };

    fetchUser();
  }, [navigate]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const db = getFirestore(app);
        const eventsCollection = collection(db, 'events');
        const eventsSnapshot = await getDocs(eventsCollection);
        let eventsList = eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        eventsList = eventsList.map(event => {
          const [year, quarter] = event.Kvartal.split(' ');
          const quarterNumber = parseInt(quarter[1], 10);
          event.parsedKvartal = new Date(year, (quarterNumber - 1) * 3);
          return event;
        });

        eventsList.sort((a, b) => b.parsedKvartal - a.parsedKvartal);

        setEvents(eventsList);

        setTotalEventsCount(eventsList.length);

        const hostedEvents = eventsList.filter(event => Array.isArray(event.Vert) && event.Vert.includes(userName));
        const userEventsCount = hostedEvents.length;
        setHostedEventsCount(userEventsCount);

        const hostedEventDetails = hostedEvents.map(event => `${event.sted_konsept}, ${event.event_type}, ${event.Kvartal}`);

        const points = hostedEvents.reduce((total, event) => {
          if (event.event_type === "Vino🍷" || event.event_type === "KL🥩") {
            return total + 4;
          } else if (event.event_type === "Blåtur🌍") {
            return total + 6;
          } else if (event.event_type === "KD🧠") {
            return total + 5;
          } else if (event.event_type === "Julebord🎄" || event.event_type === "Julegrøt🎅") {
            return total;
          }
          return total;
        }, 0);
        setHostPoints(points);

        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userDocRef, {
          host_points: points,
          hosted_events: hostedEventDetails
        });

        const participatedCount = eventsList.filter(event => event.Deltagelse.includes(userName)).length;
        setParticipatedEventsCount(participatedCount);

      } catch (error) {
        console.error('Error fetching events: ', error);
      }
    };

    if (user) {
      fetchEvents();
    }
  }, [user, userName]);

  const toggleRow = (id) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const toggleRules = () => {
    setShowRules(prevState => !prevState);
  };

  const handleCheckboxChange = async (eventId, isChecked) => {
    try {
      const db = getFirestore(app);
      const eventDocRef = doc(db, 'events', eventId);
      const eventDoc = await getDoc(eventDocRef);
      const userDocRef = doc(db, 'users', auth.currentUser.uid);

      if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        let pointsToAdd = 1;
        if (eventData.event_type === "Blåtur🌍") {
          pointsToAdd = 4;
        } else if (eventData.event_type === "KL🥩") {
          pointsToAdd = 2;
        }

        const attendedEventDetail = `${eventData.sted_konsept}, ${eventData.event_type}, ${eventData.Kvartal}`;

        if (isChecked) {
          await updateDoc(eventDocRef, {
            Deltagelse: arrayUnion(userName)
          });
          await updateDoc(userDocRef, {
            points: userPoints + pointsToAdd,
            attended_events: arrayUnion(attendedEventDetail)
          });
          setUserPoints(prevPoints => prevPoints + pointsToAdd);
          setParticipatedEventsCount(prevCount => prevCount + 1);
        } else {
          await updateDoc(eventDocRef, {
            Deltagelse: arrayRemove(userName)
          });
          await updateDoc(userDocRef, {
            points: userPoints - pointsToAdd,
            attended_events: arrayRemove(attendedEventDetail)
          });
          setUserPoints(prevPoints => prevPoints - pointsToAdd);
          setParticipatedEventsCount(prevCount => prevCount - 1);
        }

        setEvents(prevEvents => prevEvents.map(event =>
          event.id === eventId
            ? { ...event, Deltagelse: isChecked
                ? [...event.Deltagelse, userName]
                : event.Deltagelse.filter(name => name !== userName) }
            : event
        ));
      } else {
        console.error('No such event document!');
      }
    } catch (error) {
      console.error('Error updating participation: ', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('userName');
      navigate('/login');
    } catch (error) {
      console.error('Error logging out: ', error);
    }
  };

  return (
    <div>
      <div className="minProfil_text">
        <button
          className="button-17"
          onClick={toggleRules}
          style={{ backgroundColor: showRules ? 'orange' : 'gray' }}
        >
          {showRules ? 'Skjul regler' : 'Vis regler'}
        </button>
        {showRules && <Regler />}
        <button className="button-17" onClick={handleLogout}>Logg ut</button>
        <h1>Profil: {userName}</h1>
        {userName === 'Matias' && (
          <button onClick={recalculateUserPoints}>Recalculate Points</button>
        )}
        <h3>Deltagelse:</h3>
        <div>Antall events deltatt: {participatedEventsCount}</div> 
        <div>Poeng for deltagelse: {userPoints}</div>
        <br/>
        <h3>Hosting:</h3>
        <div>Antall events hostet: {hostedEventsCount}</div> 
        <div>Poeng for hosting: {hostPoints}</div> 
        <button
          className="button-17"
          onClick={() => setShowHostedEvents(prevState => !prevState)}
        >
          {showHostedEvents ? 'Skjul mine arrangerte events' : 'Vis mine arrangerte events'}
        </button>
        {showHostedEvents && (
          <table>
            <thead>
              <tr>
                <th>Sted/ konsept</th>
                <th>Event type</th>
                <th>Kvartal</th>
              </tr>
            </thead>
            <tbody>
              {events.filter(event => Array.isArray(event.Vert) && event.Vert.includes(userName)).map(event => (
                <tr key={event.id}>
                  <td>{event.sted_konsept}</td>
                  <td>{event.event_type}</td>
                  <td>{event.Kvartal}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <br/>
        <br/>
        <h5>Huk av under dersom du har deltatt:</h5>
        <div className="totalEventsCount">Totalt antall events: {totalEventsCount}</div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Sted/ konsept</th>
            <th>Event type</th>
            <th>Kvartal</th>
            <th>Deltatt?</th>
            <th>Pts</th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => (
            <React.Fragment key={event.id}>
              <tr className={!event.Deltagelse.includes(userName) ? 'not-registered' : ''}>
                <td>{event.sted_konsept}</td>
                <td>{event.event_type}</td>
                <td>{event.Kvartal}</td>
                <td>Deltatt? 
                  <input className="checkbox"
                    type="checkbox"
                    checked={event.Deltagelse.includes(userName)}
                    onChange={(e) => handleCheckboxChange(event.id, e.target.checked)}
                  />
                  <button onClick={() => toggleRow(event.id)}>
                    {expandedRows[event.id] ? 'Vis mindre' : 'Vis mer'}
                  </button>
                </td>
                <td>{event.event_type === "Blåtur🌍" ? 4 : event.event_type === "KL🥩" ? 2 : 1}</td>
              </tr>
              {expandedRows[event.id] && (
                <tr>
                  <td colSpan="5">
                    <div className="minProfil_text">
                      <ul>
                        Vert: {Array.isArray(event.Vert) ? event.Vert.join(', ') : ''}.
                        <br />
                        <div className="deltagelse_underline">Deltagelse:</div>
                        {event.Deltagelse.map((participant, index) => (
                          <p key={index}>{participant}</p>
                        ))}
                      </ul>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MinProfil;
