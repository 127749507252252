
import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from './firebase-config';
import './Bilder.css';

const Bilder = () => {
  const [events, setEvents] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [user, setUser] = useState(null);

  const auth = getAuth(app);

  const fetchEvents = async () => {
    try {
      const db = getFirestore(app);
      const eventsCollection = collection(db, 'events');
      const eventsSnapshot = await getDocs(eventsCollection);
      let eventsList = eventsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Parse and sort the events based on Kvartal
      eventsList = eventsList.map(event => {
        const [year, quarter] = event.Kvartal.split(' ');
        const quarterNumber = parseInt(quarter[1], 10);
        event.parsedKvartal = new Date(year, (quarterNumber - 1) * 3);
        return event;
      });

      eventsList.sort((a, b) => b.parsedKvartal - a.parsedKvartal);
      
      setEvents(eventsList);
    } catch (error) {
      console.error('Error fetching events: ', error);
    }
  };

  useEffect(() => {
    fetchEvents();
    const fetchUser = () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        console.log('User is authenticated:', currentUser);
      } else {
        console.log('User is not authenticated');
      }
      setUser(currentUser);
    };
    fetchUser();

    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        console.log('Auth state changed: User is authenticated:', user);
      } else {
        console.log('Auth state changed: User is not authenticated');
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const toggleRow = (id) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleImageUpload = async (eventId, event) => {
    const files = event.target.files;
    if (!files.length) return;

    if (!user) {
      console.error('User is not authenticated. Cannot upload images.');
      return;
    }

    const storage = getStorage(app);
    const db = getFirestore(app);
    const eventDocRef = doc(db, 'events', eventId);

    for (const file of files) {
      const storageRef = ref(storage, `events/${eventId}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Error uploading file:', error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            await updateDoc(eventDocRef, {
              images: arrayUnion(downloadURL)
            });
            console.log('Image uploaded and URL saved to Firestore:', downloadURL);

            // Update the local state to reflect the new image immediately
            setEvents(prevEvents => prevEvents.map(event => 
              event.id === eventId 
                ? { ...event, images: [...(event.images || []), downloadURL] }
                : event
            ));
          } catch (error) {
            console.error('Error updating Firestore with image URL:', error);
          }
        }
      );
    }
  };

  const handleImageDelete = async (eventId, imageUrl) => {
    try {
      const storage = getStorage(app);
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);

      const db = getFirestore(app);
      const eventDocRef = doc(db, 'events', eventId);
      await updateDoc(eventDocRef, {
        images: arrayRemove(imageUrl)
      });

      setEvents(prevEvents => prevEvents.map(event => 
        event.id === eventId 
          ? { ...event, images: event.images.filter(url => url !== imageUrl) }
          : event
      ));
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        console.error('Image does not exist, removing reference from Firestore');
        const db = getFirestore(app);
        const eventDocRef = doc(db, 'events', eventId);
        await updateDoc(eventDocRef, {
          images: arrayRemove(imageUrl)
        });

        setEvents(prevEvents => prevEvents.map(event => 
          event.id === eventId 
            ? { ...event, images: event.images.filter(url => url !== imageUrl) }
            : event
        ));
      } else {
        console.error('Error deleting image:', error);
      }
    }
  };

  const handleImageClick = (url) => {
    setSelectedImage(url);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <div className="bilder_top_text">
        <h1>🖼️Bilder🖼️</h1>
        {!user && 'Du må være innlogget på "Min profil" for å se og laste opp bilder'}
      </div>
      <table>
        <thead>
          <tr>
            <th>Sted/ konsept</th>
            <th>Event type</th>
            <th>Kvartal</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => (
            <React.Fragment key={event.id}>
              <tr>
                <td>{event.sted_konsept}</td>
                <td>{event.event_type}</td>
                <td>{event.Kvartal}</td>
                <td>
                  <button
                    onClick={() => toggleRow(event.id)}
                    style={{ backgroundColor: event.images && event.images.length > 0 ? 'orange' : 'grey' }}
                  >
                    {expandedRows[event.id] ? 'Skjul bilder' : 'Bilder'}
                  </button>
                </td>
              </tr>
              {expandedRows[event.id] && (
                <tr>
                  <td colSpan="4">
                    <div className="images-section">
                      <input type="file" multiple onChange={(e) => handleImageUpload(event.id, e)} />
                      <button onClick={() => setEvents(prevEvents => prevEvents.map(ev => ev.id === event.id ? { ...ev, showDelete: !ev.showDelete } : ev))}>
                        Slett
                      </button>
                      <div className="uploaded-images-grid">
                        {event.images && event.images.map((url, index) => (
                          <div key={index} className="image-container">
                            <img
                              src={url}
                              alt={`Event ${event.id} - ${index}`}
                              onClick={() => handleImageClick(url)}
                              className="thumbnail"
                            />
                            {event.showDelete && (
                              <span className="delete-button" onClick={() => {
                                if (window.confirm('Er du sikker på at du vil slette dette bildet?')) {
                                  handleImageDelete(event.id, url);
                                }
                              }}>x</span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {selectedImage && (
        <div className="modal" onClick={handleCloseModal}>
          <span className="close">&times;</span>
          <img className="modal-content" src={selectedImage} alt="Enlarged view" />
          <div className="caption">Event Image</div>
        </div>
      )}
    </div>
  );
};

export default Bilder;
