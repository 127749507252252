import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MyNavbar from './MyNavbar';
import Home from './Home';
//import Regler from './Regler';
import Scoreboard from './Scoreboard';
import MinePoeng from './Sc_MinePoeng';
import './App.css';
import './index.css';
import { getAuth, onAuthStateChanged, getRedirectResult } from 'firebase/auth';
import Login from './Login';
import MinProfil from './MinProfil';
import Bilder from './Bilder';
import { app } from './firebase-config';
import KlRating from './KlRating';

const auth = getAuth(app);

const PrivateRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        try {
          const result = await getRedirectResult(auth);
          if (result && result.user) {
            setUser(result.user);
          }
        } catch (error) {
          console.error('Error handling redirect result: ', error);
        }
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <div>
      <Helmet>
        <title>LaugHub</title>
      </Helmet>
      <Router>
        <MyNavbar />
        <div className="app-container">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/bilder" element={<Bilder />} />
            <Route path="/scoreboard" element={<Scoreboard />} />
            <Route path="/scoreboard/minepoeng" element={<MinePoeng />} />
            <Route path="/klrating" element={<KlRating />} />
            <Route path="/minprofil" element={<PrivateRoute><MinProfil /></PrivateRoute>} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;