// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
/*import firebase from 'firebase/app';
import 'firebase/auth';  // If using authentication
import 'firebase/firestore';  // If using Firestore
import 'firebase/database';  // If using Realtime Database
*/

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVNAlPPrksV-T_rKiCn97x8Qma24wHn0E",
  authDomain: "kulinarisklaug.firebaseapp.com",
  projectId: "kulinarisklaug",
  storageBucket: "kulinarisklaug.appspot.com",
  messagingSenderId: "473950854896",
  appId: "1:473950854896:web:0b65a8cb00797a80b92c35",
  measurementId: "G-FB7GX26EBW"
};

console.log("Firebase config: ", firebaseConfig); // Add this line to log the config

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const database = getDatabase(app);
const storage = getStorage(app);

// Export the services to use in other parts of your application
export { auth, firestore, database, app, storage  };
export default firestore;