import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app } from './firebase-config';
import './Bilder.css'; // Reuse the CSS from Bilder for consistent styling

const KlRating = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [userName, setUserName] = useState(null);

  const allowedUsers = ["Matias", "Glenn"];
  
  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const db = getFirestore(app);
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("Current User Name: ", userData.name); // Debug log
          setUserName(userData.name);
        } else {
          console.error('User document not found.');
          setUserName(null);
        }
      } else {
        console.log("No user is signed in.");
        setUserName(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const db = getFirestore(app);
        const eventsCollection = collection(db, 'events');
        const eventsSnapshot = await getDocs(eventsCollection);
        const eventsList = eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Filter events with event_type 'KL🥩' and sort by rating in descending order
        const filteredEvents = eventsList.filter(event => event.event_type === 'KL🥩');
        filteredEvents.sort((a, b) => b.rating - a.rating);
        
        setEvents(filteredEvents);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching events: ', error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleRatingChange = (eventId, newRating) => {
    setEvents(events.map(event => 
      event.id === eventId ? { ...event, rating: newRating } : event
    ));
  };

  const handleSetClick = async () => {
    const db = getFirestore(app);
    try {
      for (const event of events) {
        const eventDocRef = doc(db, 'events', event.id);
        await updateDoc(eventDocRef, { rating: event.rating });
      }
      setIsEditing(false);
      alert('KL-ratings lagret!');
    } catch (error) {
      console.error('Error updating ratings: ', error);
      alert('Error updating ratings. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>🏆KL-ranking🏆</h1>
      <p>KL-ratings blir gjort av Glenn, eller med fullmakt fra Glenn, basert på mat, drikke og stemning. </p>
      {events.length === 0 ? (
        <p>Logg inn på "Min profil" for å se KL-ratings</p>
      ) : (
        <div>
          {allowedUsers.includes(userName) && (
            <button onClick={isEditing ? handleSetClick : handleEditClick}>
              {isEditing ? 'Lagre' : 'Rediger KL-ratings'}
            </button>
          )}
          <table>
            <thead>
              <tr>
                <th>Sted/ Konsept</th>
                <th>Event Type</th>
                <th>Kvartal</th>
                <th>🏆Rating🏆</th>
              </tr>
            </thead>
            <tbody>
              {events.map(event => (
                <tr key={event.id}>
                  <td>{event.sted_konsept}</td>
                  <td>{event.event_type}</td>
                  <td>{event.Kvartal}</td>
                  <td>
                    {isEditing && allowedUsers.includes(userName) ? (
                      <input 
                        type="number"
                        value={event.rating}
                        onChange={(e) => handleRatingChange(event.id, e.target.value)}
                        className="rating-input"
                      />
                    ) : (
                      event.rating
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default KlRating;
