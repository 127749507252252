/*
import React, { useEffect, useState, useCallback } from 'react';
import { getFirestore, collection, getDocs, updateDoc, arrayUnion, doc } from 'firebase/firestore';
import { app } from './firebase-config';
import { getAuth } from 'firebase/auth';

const Utmerkelser = () => {
  const [awards, setAwards] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const auth = getAuth(app);

  const updateHostedEventsForAllUsers = useCallback(async (usersList, eventsList) => {
    try {
      const db = getFirestore(app);
      const currentUser = auth.currentUser;

      for (const user of usersList) {
        const hostedEvents = eventsList.filter(event => Array.isArray(event.Vert) && event.Vert.includes(user.name));
        const hostedEventDetails = hostedEvents.map(event => `${event.sted_konsept}, ${event.event_type}, ${event.Kvartal}`);

        if (currentUser && currentUser.uid === user.id) {
          const userDocRef = doc(db, 'users', user.id);
          await updateDoc(userDocRef, {
            hosted_events: hostedEventDetails
          });

          console.log(`Updating hosted events for user ${user.name}:`, hostedEventDetails);
        }
      }
    } catch (error) {
      console.error('Error updating hosted events for all users:', error.code, error.message);
      setError('Error updating hosted events for all users: ' + error.message);
    }
  }, [auth.currentUser]);

  useEffect(() => {
    const fetchAwards = async () => {
      const db = getFirestore(app);
      const usersCollection = collection(db, 'users');
      const eventsCollection = collection(db, 'events');
      const currentUser = auth.currentUser;

      try {
        const usersSnapshot = await getDocs(usersCollection);
        const eventsSnapshot = await getDocs(eventsCollection);

        const usersList = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        const eventsList = eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        console.log('Users List:', usersList);
        console.log('Events List:', eventsList);

        await updateHostedEventsForAllUsers(usersList, eventsList);

        const festivitySpecialistUsers = usersList.filter(user => {
          if (!user.hosted_events) return false;
          const eventCounts = user.hosted_events.reduce((acc, event) => {
            const eventType = event.split(', ')[1];
            if (["Vino🍷", "Blåtur🌍", "KL🥩", "KD🧠"].includes(eventType)) {
              acc[eventType] = (acc[eventType] || 0) + 1;
            }
            return acc;
          }, {});

          return Object.values(eventCounts).some(count => count >= 2);
        });

        const maestroOfCeremoniesUsers = usersList.filter(user => {
          if (!user.hosted_events) return false;
          const eventTypes = user.hosted_events.map(event => event.split(', ')[1]);
          const uniqueEventTypes = new Set(eventTypes);
          return ["Vino🍷", "Blåtur🌍", "KL🥩", "KD🧠"].every(type => uniqueEventTypes.has(type));
        });

        const klScoreMasterUsers = eventsList.filter(event =>
          event.event_type === 'KL🥩' && event.rating > 3.6
        ).map(event => event.Vert).flat();

        const klScoreMasterUsersList = usersList.filter(user =>
          klScoreMasterUsers.includes(user.name)
        );

        const vinoSommelierUsers = usersList.filter(user => {
          if (!user.hosted_events) return false;
          const vinoCount = user.hosted_events.reduce((count, event) => {
            const eventType = event.split(', ')[1];
            return eventType === "Vino🍷" ? count + 1 : count;
          }, 0);
          return vinoCount >= 3;
        });

        const klConnoisseurUsers = usersList.filter(user => {
          if (!user.hosted_events) return false;
          const klCount = user.hosted_events.reduce((count, event) => {
            const eventType = event.split(', ')[1];
            return eventType === "KL🥩" ? count + 1 : count;
          }, 0);
          return klCount >= 3;
        });

        const awardsData = [
          {
            name: '⚜️Festivity Specialist⚜️',
            description: 'Arrangert samme type event (Vino🍷, Blåtur🌍, KL🥩 eller KD🧠) to eller flere ganger',
            users: festivitySpecialistUsers.map(user => user.name).join(', '),
          },
          {
            name: '🎗️KL Score Master🎗️',
            description: 'Hosted a KL event with the score above 3.6',
            users: klScoreMasterUsersList.map(user => user.name).join(', '),
          },
          {
            name: '🥇Maestro of Ceremonies🥇',
            description: 'Hosted one of each event type (Vino🍷, Blåtur🌍, KL🥩 and KD🧠)',
            users: maestroOfCeremoniesUsers.map(user => user.name).join(', '),
          },
          {
            name: '🍷Vino Sommelier🍷',
            description: 'Hosted three Vino🍷 events',
            users: vinoSommelierUsers.map(user => user.name).join(', '),
          },
          {
            name: '🥩KL Connoisseur🥩',
            description: 'Hosted three KL🥩 events',
            users: klConnoisseurUsers.map(user => user.name).join(', '),
          }
        ];

        console.log('Awards Data:', awardsData);

        setAwards(awardsData);

        const updateFirestoreAwards = async (userId, awardName) => {
          try {
            if (currentUser && currentUser.uid === userId) {
              const userDocRef = doc(db, 'users', userId);
              await updateDoc(userDocRef, {
                awards: arrayUnion(awardName)
              });
            }
          } catch (error) {
            console.error(`Error updating awards for user ${userId}:`, error.code, error.message);
            throw error;
          }
        };

        for (const user of festivitySpecialistUsers) {
          await updateFirestoreAwards(user.id, '⚜️Festivity Specialist⚜️');
        }

        for (const user of klScoreMasterUsersList) {
          await updateFirestoreAwards(user.id, '🎗️KL Score Master🎗️');
        }

        for (const user of maestroOfCeremoniesUsers) {
          await updateFirestoreAwards(user.id, '🥇Maestro of Ceremonies🥇');
        }

        for (const user of vinoSommelierUsers) {
          await updateFirestoreAwards(user.id, '🍷Vino Sommelier🍷');
        }

        for (const user of klConnoisseurUsers) {
          await updateFirestoreAwards(user.id, '🥩KL Connoisseur🥩');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error updating Firestore:', error.code, error.message);
        setError('Error updating Firestore: ' + error.message);
        setLoading(false);
      }
    };

    fetchAwards();
  }, [auth.currentUser, updateHostedEventsForAllUsers]);

  const toggleRow = (index) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>🎖️Utmerkelser🎖️</h2>
      <table>
        <thead>
          <tr>
            <th>Award type</th>
            <th>Vinnere</th>
          </tr>
        </thead>
        <tbody>
          {awards.map((award, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  {award.name}
                  <button onClick={() => toggleRow(index)}>
                    {expandedRows[index] ? 'Skjul beskrivelse' : 'Vis beskrivelse'}
                  </button>
                </td>
                <td>
                  {award.users}
                </td>
              </tr>
              {expandedRows[index] && (
                <tr>
                  <td colSpan="2">
                    {award.description}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Utmerkelser;
*/

import React, { useEffect, useState, useCallback } from 'react';
import { getFirestore, collection, getDocs, updateDoc, arrayUnion, doc } from 'firebase/firestore';
import { app } from './firebase-config';
import { getAuth } from 'firebase/auth';

const Utmerkelser = () => {
  const [awards, setAwards] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const auth = getAuth(app);

  const updateHostedEventsForAllUsers = useCallback(async (usersList, eventsList) => {
    try {
      const db = getFirestore(app);
      const currentUser = auth.currentUser;

      for (const user of usersList) {
        const hostedEvents = eventsList.filter(event => Array.isArray(event.Vert) && event.Vert.includes(user.name));
        const hostedEventDetails = hostedEvents.map(event => `${event.sted_konsept}, ${event.event_type}, ${event.Kvartal}`);

        if (currentUser && currentUser.uid === user.id) {
          const userDocRef = doc(db, 'users', user.id);
          await updateDoc(userDocRef, {
            hosted_events: hostedEventDetails
          });

          console.log(`Updating hosted events for user ${user.name}:`, hostedEventDetails);
        }
      }
    } catch (error) {
      console.error('Error updating hosted events for all users:', error.code, error.message);
      setError('Error updating hosted events for all users: ' + error.message);
    }
  }, [auth.currentUser]);

  useEffect(() => {
    const fetchAwards = async () => {
      const db = getFirestore(app);
      const usersCollection = collection(db, 'users');
      const eventsCollection = collection(db, 'events');
      const currentUser = auth.currentUser;

      try {
        const usersSnapshot = await getDocs(usersCollection);
        const eventsSnapshot = await getDocs(eventsCollection);

        const usersList = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        const eventsList = eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        console.log('Users List:', usersList);
        console.log('Events List:', eventsList);

        await updateHostedEventsForAllUsers(usersList, eventsList);

        const festivitySpecialistUsers = usersList.filter(user => {
          if (!user.hosted_events) return false;
          const eventCounts = user.hosted_events.reduce((acc, event) => {
            const eventType = event.split(', ')[1];
            if (["Vino🍷", "Blåtur🌍", "KL🥩", "KD🧠"].includes(eventType)) {
              acc[eventType] = (acc[eventType] || 0) + 1;
            }
            return acc;
          }, {});

          return Object.values(eventCounts).some(count => count >= 2);
        });

        const maestroOfCeremoniesUsers = usersList.filter(user => {
          if (!user.hosted_events) return false;
          const eventTypes = user.hosted_events.map(event => event.split(', ')[1]);
          const uniqueEventTypes = new Set(eventTypes);
          return ["Vino🍷", "Blåtur🌍", "KL🥩", "KD🧠"].every(type => uniqueEventTypes.has(type));
        });

        const klScoreMasterUsers = eventsList.filter(event =>
          event.event_type === 'KL🥩' && event.rating > 3.6
        ).map(event => event.Vert).flat();

        const klScoreMasterUsersList = usersList.filter(user =>
          klScoreMasterUsers.includes(user.name)
        );

        const vinoSommelierUsers = usersList.filter(user => {
          if (!user.hosted_events) return false;
          const vinoCount = user.hosted_events.reduce((count, event) => {
            const eventType = event.split(', ')[1];
            return eventType === "Vino🍷" ? count + 1 : count;
          }, 0);
          return vinoCount >= 3;
        });

        const klConnoisseurUsers = usersList.filter(user => {
          if (!user.hosted_events) return false;
          const klCount = user.hosted_events.reduce((count, event) => {
            const eventType = event.split(', ')[1];
            return eventType === "KL🥩" ? count + 1 : count;
          }, 0);
          return klCount >= 3;
        });

        const blaturVoyagerUsers = usersList.filter(user => {
          if (!user.hosted_events) return false;
          const blaturCount = user.hosted_events.reduce((count, event) => {
            const eventType = event.split(', ')[1];
            return eventType === "Blåtur🌍" ? count + 1 : count;
          }, 0);
          return blaturCount >= 3;
        });

        const awardsData = [
          {
            name: '⚜️Festivity Specialist⚜️',
            description: 'Arrangert samme type event (Vino🍷, Blåtur🌍, KL🥩 eller KD🧠) to eller flere ganger',
            users: festivitySpecialistUsers.map(user => user.name).join(', '),
          },
          {
            name: '🎗️KL Score Master🎗️',
            description: 'Hosted a KL event with the score above 3.6',
            users: klScoreMasterUsersList.map(user => user.name).join(', '),
          },
          {
            name: '🥇Maestro of Ceremonies🥇',
            description: 'Hosted one of each event type (Vino🍷, Blåtur🌍, KL🥩 and KD🧠)',
            users: maestroOfCeremoniesUsers.map(user => user.name).join(', '),
          },
          {
            name: '🍷Vino Sommelier🍷',
            description: 'Hosted three Vino🍷 events',
            users: vinoSommelierUsers.map(user => user.name).join(', '),
          },
          {
            name: '🥩KL Connoisseur🥩',
            description: 'Hosted three KL🥩 events',
            users: klConnoisseurUsers.map(user => user.name).join(', '),
          },
          {
            name: '🌍Blåtur Voyager🌍',
            description: 'Hosted three Blåtur🌍 events',
            users: blaturVoyagerUsers.map(user => user.name).join(', '),
          }
        ];

        console.log('Awards Data:', awardsData);

        setAwards(awardsData);

        const updateFirestoreAwards = async (userId, awardName) => {
          try {
            if (currentUser && currentUser.uid === userId) {
              const userDocRef = doc(db, 'users', userId);
              await updateDoc(userDocRef, {
                awards: arrayUnion(awardName)
              });
            }
          } catch (error) {
            console.error(`Error updating awards for user ${userId}:`, error.code, error.message);
            throw error;
          }
        };

        for (const user of festivitySpecialistUsers) {
          await updateFirestoreAwards(user.id, '⚜️Festivity Specialist⚜️');
        }

        for (const user of klScoreMasterUsersList) {
          await updateFirestoreAwards(user.id, '🎗️KL Score Master🎗️');
        }

        for (const user of maestroOfCeremoniesUsers) {
          await updateFirestoreAwards(user.id, '🥇Maestro of Ceremonies🥇');
        }

        for (const user of vinoSommelierUsers) {
          await updateFirestoreAwards(user.id, '🍷Vino Sommelier🍷');
        }

        for (const user of klConnoisseurUsers) {
          await updateFirestoreAwards(user.id, '🥩KL Connoisseur🥩');
        }

        for (const user of blaturVoyagerUsers) {
          await updateFirestoreAwards(user.id, '🌍Blåtur Voyager🌍');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error updating Firestore:', error.code, error.message);
        setError('Error updating Firestore: ' + error.message);
        setLoading(false);
      }
    };

    fetchAwards();
  }, [auth.currentUser, updateHostedEventsForAllUsers]);

  const toggleRow = (index) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>🎖️Utmerkelser🎖️</h2>
      <table>
        <thead>
          <tr>
            <th>Award type</th>
            <th>Vinnere</th>
          </tr>
        </thead>
        <tbody>
          {awards.map((award, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  {award.name}
                  <button onClick={() => toggleRow(index)}>
                    {expandedRows[index] ? 'Skjul beskrivelse' : 'Vis beskrivelse'}
                  </button>
                </td>
                <td>
                  {award.users}
                </td>
              </tr>
              {expandedRows[index] && (
                <tr>
                  <td colSpan="2">
                    {award.description}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Utmerkelser;
