import { getFirestore, collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { app } from './firebase-config';

const db = getFirestore(app);

export const recalculateUserPoints = async () => {
  try {
    const usersCollection = collection(db, 'users');
    const eventsCollection = collection(db, 'events');

    const usersSnapshot = await getDocs(usersCollection);
    const eventsSnapshot = await getDocs(eventsCollection);

    const usersList = usersSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    const eventsList = eventsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    for (let user of usersList) {
      let newPoints = 0;
      let hostPoints = 0;

      for (let event of eventsList) {
        if (event.Deltagelse.includes(user.name)) {
          if (event.event_type === "Blåtur🌍") {
            newPoints += 4;
          } else if (event.event_type === "KL🥩") {
            newPoints += 2; // Updated logic for KL🥩
          } else {
            newPoints += 1;
          }
        }

        if (Array.isArray(event.Vert) && event.Vert.includes(user.name)) {
          if (event.event_type === "Vino🍷" || event.event_type === "KL🥩") {
            hostPoints += 4;
          } else if (event.event_type === "Blåtur🌍") {
            hostPoints += 6;
          } else if (event.event_type === "KD🧠") {
            hostPoints += 5;
          } else if (event.event_type === "Julebord🎄" || event.event_type === "Julegrøt🎅") {
            hostPoints += 0; // Assuming 0 points for these events
          }
        }
      }

      const userDocRef = doc(db, 'users', user.id);
      await updateDoc(userDocRef, {
        points: newPoints,
        host_points: hostPoints
      });
    }

    console.log('User points recalculated successfully.');
  } catch (error) {
    console.error('Error recalculating user points: ', error);
  }
};
