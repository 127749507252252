import React from 'react';
import './Regler.css';

function Regler() {
  return (
  <div>
    <h2>📝Regler📝</h2>
      <div className="regler-container">
        <p>Vi har for øyeblikket to konkurranser; "Beste Deltager" og "Beste Arrangør"!<br />
        <br />
        Poeng:
        <ul>
          <li>Julegrøt🎅: Deltagelse=1 poeng. Arrangert=0 poeng &#40;rullerer ikke&#41;</li>
          <li>Julebord🎄: Deltagelse=1 poeng. Arrangert=0 poeng &#40;rullerer ikke&#41;</li>
          <li>Vino🍷: Deltagelse=1 poeng. Arrangert=4 poeng</li>
          <li>KL🥩: Deltagelse=2 poeng. Arrangert=4 poeng</li>
          <li>Kunnskapsdeling🧠: Deltagelse=1 poeng. Arrangert=5 poeng</li>
          <li>Blåtur🌍: Deltagelse=4 poeng. Arrangert=6 poeng</li>
        </ul>
        Utnevnelse av arrangører:
        <ul>
          <li>På slutten av hvert event skal ny arrangør for neste event utnevnes</li>
          <li>Ny arrangør skal være en av de med minst arrangerte events</li>
          <li>Ny arrangør trenger ikke å være til stede for å utnevnes</li>
        </ul>
        Hyppighet av events:
        <ul>
          <li>Vino🍷: Kvartalsvis</li>
          <li>KL🥩: Kvartalsvis</li>
          <li>Kunnskapsdeling🧠: Ubestemt</li>
          <li>Blåtur🌍: Årlig</li>
        </ul>
        </p>
      </div>
  </div>
  );
}

export default Regler;
