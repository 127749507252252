
import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { app } from './firebase-config'; // Adjust the import path as necessary
import Regler from './Regler'; // Import the Regler component
import Utmerkelser from './Utmerkelser'; // Import the Utmerkelser component
import './Scoreboard.css';

const Scoreboard = () => {
  const [usersByYear, setUsersByYear] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedYears, setExpandedYears] = useState({});
  const [activeTable, setActiveTable] = useState('deltager'); // State for active table

  useEffect(() => {
    const fetchUsers = async () => {
      const db = getFirestore(app);
      const usersCollection = collection(db, 'users');
      const eventsCollection = collection(db, 'events');

      try {
        const usersSnapshot = await getDocs(usersCollection);
        const eventsSnapshot = await getDocs(eventsCollection);

        const usersList = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        const eventsList = eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Sort users by points for 'deltager' table
        const sortedByPoints = [...usersList].sort((a, b) => b.points - a.points);
        // Sort users by host_points for 'arrangør' table
        const sortedByHostPoints = [...usersList].sort((a, b) => (b.host_points || 0) - (a.host_points || 0));

        setUsers({ sortedByPoints, sortedByHostPoints });

        const years = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017];

        const usersByYear = years.reduce((acc, year) => {
          const filteredEvents = eventsList.filter(event => event.Kvartal.includes(year.toString()));
          const usersWithPoints = usersList.map(user => {
            const attendedEvents = filteredEvents.filter(event => event.Deltagelse.includes(user.name));
            const points = attendedEvents.reduce((acc, event) => acc + (event.event_type === "Blåtur🌍" ? 4 : 1), 0);
            return {
              ...user,
              points,
            };
          }).sort((a, b) => b.points - a.points);

          acc[year] = usersWithPoints;
          return acc;
        }, {});

        setUsersByYear(usersByYear);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const toggleExpand = (year) => {
    setExpandedYears(prevState => ({
      ...prevState,
      [year]: !prevState[year]
    }));
  };

  const getTopScorers = (users) => {
    if (users.length === 0) return [];
    const highestScore = users[0].points;
    return users.filter(user => user.points === highestScore);
  };

  const setTable = (table) => {
    setActiveTable(table);
  };

  const showTableOrComponent = () => {
    if (activeTable === 'rules') return <Regler />;
    if (activeTable === 'awards') return <Utmerkelser />;
    if (activeTable === 'deltager') {
      return (
        <div>
          <ul>
            <li>Julegrøt🎅: Deltagelse=1 poeng</li>
            <li>Julebord🎄: Deltagelse=1 poeng</li>
            <li>Vino🍷: Deltagelse=1 poeng</li>
            <li>KL🥩: Deltagelse=2 poeng</li>
            <li>Kunnskapsdeling🧠: Deltagelse=1 poeng</li>
            <li>Blåtur🌍: Deltagelse=4 poeng</li>
          </ul>
          {Object.keys(usersByYear).sort((a, b) => b - a).map(year => (
            <div key={year}>
              <h4>Beste deltaker {year}</h4>
              <table>
                <thead>
                  <tr>
                    <th>Navn</th>
                    <th>Deltakerpoeng</th>
                  </tr>
                </thead>
                <tbody>
                  {getTopScorers(usersByYear[year]).map(user => (
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.points}</td>
                    </tr>
                  ))}
                  {expandedYears[year] && usersByYear[year].slice(getTopScorers(usersByYear[year]).length).map(user => (
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={() => toggleExpand(year)}>
                {expandedYears[year] ? 'Vis mindre' : 'Vis resten'}
              </button>
            </div>
          ))}
        </div>
      );
    }
    if (activeTable === 'arrangør') {
      return (
        <div>
          <ul>
            <li>Julegrøt🎅: Arrangert=0 poeng &#40;rullerer ikke&#41;</li>
            <li>Julebord🎄: Arrangert=0 poeng &#40;rullerer ikke&#41;</li>
            <li>Vino🍷: Arrangert=4 poeng</li>
            <li>KL🥩: Arrangert=4 poeng</li>
            <li>Kunnskapsdeling🧠: Arrangert=5 poeng</li>
            <li>Blåtur🌍: Arrangert=6 poeng</li>
          </ul>
          <h2>👑Beste arrangør👑</h2>
          <table>
            <thead>
              <tr>
                <th>Navn</th>
                <th>Vertpoeng</th>
              </tr>
            </thead>
            <tbody>
              {users.sortedByHostPoints.map(user => (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.host_points || 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Logg inn på "Min profil" for å se Scoreboard</div>;
  }

  return (
    <div>
      <div className="button-container">
        <button
          className="button-17"
          onClick={() => setTable('deltager')}
          style={{ backgroundColor: activeTable === 'deltager' ? 'orange' : 'gray' }}
        >
          Beste deltaker
        </button>
        <button
          className="button-17"
          onClick={() => setTable('arrangør')}
          style={{ backgroundColor: activeTable === 'arrangør' ? 'orange' : 'gray' }}
        >
          Beste arrangør
        </button>
        <button
          className="button-17"
          onClick={() => setTable('rules')}
          style={{ backgroundColor: activeTable === 'rules' ? 'orange' : 'gray' }}
        >
          Regler
        </button>
        <button
          className="button-17"
          onClick={() => setTable('awards')}
          style={{ backgroundColor: activeTable === 'awards' ? 'orange' : 'gray' }}
        >
          Utmerkelser
        </button>
      </div>
      {showTableOrComponent()}
    </div>
  );
};

export default Scoreboard;
