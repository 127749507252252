import React from 'react';
import './Sc_MinePoeng.css';

function MinePoeng() {
  return (
  <div>
    <h2>Mine Poeng</h2>
      <div className="regler-container">
        <p>Kommer snart<br />
        <ul>
        </ul>
        </p>
      </div>
  </div>
  );
}

export default MinePoeng;
